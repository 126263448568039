import 'core-js/stable'
import 'regenerator-runtime/runtime'
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

window.$ = $;
window.jQuery = $;

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' })
                         .then(function(registration) {
                           console.log('[Companion]', 'Service worker registered!')
                           console.log(registration)
                         })
}
